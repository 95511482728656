import React from "react";
import "./Contact.css";
import { Form, Button, Icon } from "semantic-ui-react";
import { useFormik } from "formik";
import { map } from "lodash";
import { initialValues, validationSchema } from "./Contact.form";
import { Contact_Api } from "../../api/Contact";
import Swal from "sweetalert2";

const contactController = new Contact_Api();

const socialData = [
  {
    type: "facebook",
    link: "https://facebook.com/villachumpeco/?_rdc=1&_rdr",
    color: "facebook",
  },
  {
    type: "instagram",
    link: "https://www.instagram.com/laposadadelturista_llifen/",
    color: "pink",
  },
  {
    type: "whatsapp",
    link: "https://wa.me/56982768598",
    color: "green",
  },
];

export function Contact() {
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await contactController.contacto(formValue);
        Enviado();
        formik.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const Enviado = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "El Mensaje fue enviado exitosamente",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  return (
    <section id="contactoSection">
      <div className="contenido-contacto">
        <div className="contenido-contacto-left">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                label="Nombre"
                name="nombre"
                placeholder="Nombre"
                onChange={formik.handleChange}
                value={formik.values.nombre}
                error={formik.errors.nombre}
              />

              <Form.Input
                label="Asunto"
                name="asunto"
                placeholder="Asunto"
                onChange={formik.handleChange}
                value={formik.values.asunto}
                error={formik.errors.asunto}
              />
            </Form.Group>

            <Form.Input
              label="Correo electrónico"
              name="email"
              placeholder="Correo electrónico"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
            />

            <Form.TextArea
              label="Mensaje"
              name="mensaje"
              placeholder="Mensaje"
              onChange={formik.handleChange}
              value={formik.values.mensaje}
              error={formik.errors.mensaje}
            />
            <Form.Button
              type="submit"
              color="teal"
              loading={formik.isSubmitting}
            >
              ENVIAR
            </Form.Button>
          </Form>
        </div>
        <div className="contenido-center">
          <h2>Redes Sociales</h2>
          <div className="socials">
            {map(socialData, (social) => (
              <Button
                key={social.type}
                as="a"
                target="_blank"
                href={social.link}
                color={social.color}
                icon={social.type}
                size="big"
              ></Button>
            ))}
          </div>
        </div>
        <div className="contenido-right">
          <div className="contenido-contacto-right">
            <h2>Ubicación</h2>
            <div className="Info">
              <p>Llifén, Camino Calcurrupe S/N</p>
              <p>
                <Icon name="phone" /> +56 9 82768598
              </p>
              <p>
                <Icon name="mail" /> contacto@laposadadelturista.cl
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <p>La Posada del Turista © Todos los derechos reservados</p>
      </div>
    </section>
  );
}
